<template>
  <div>
    <div style="height: 100vh">
      <iframe
        :src="src"
        class="fill-height"
        style="border: 0px;width: 100%; height: 100%;"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArViewerPage',
  data() {
    return {
      glbId: '',
      baseUrl: 'https://static.fantasykombat.com/3d/fantasyfighters',
      src: '',
    }
  },
  mounted() {
    this.glbId = this.$route.query.id
    this.src = `${this.baseUrl}/${this.glbId}/index.html`
  },
}
</script>

<style>

#sig-move {
  margin-top: 20px;
  color: #fff;
}

button#ar-button {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 0px 13px !important;
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
  background: #312D4B;
  opacity: 0.9;
}

button#sig-move.hide {
  display: none;
}

small.ar-text {
  top: 91px;
  position: absolute;
  width: 90px;
  color: #fff;
  font-size: 10px;
  text-align: center;
}
</style>
